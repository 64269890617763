import * as React from "react";
import type { HeadFC } from "gatsby";

import { Web3Providers } from "../contexts/AppContext";

import { NavBar } from "../components/NavBar/NavBar";

import { PageHeader } from "../components/PageHeader/PageHeader";
import {
  GoHomeButton,
  YellowButton,
} from "../components/Items/Buttons/Buttons";
import { getQueryParams, setQueryParams } from "react-use-query-param-string";
import { ethers } from "ethers";
import { EntryVerificationStatus } from "../types";
import { SEO } from "../components/SEO";
import { decryptStr } from "../utils";
// import { useQueryParam, StringParam, NumberParam } from "use-query-params";

const TicketPage = () => {
  return (
    <Web3Providers>
      <TicketPageComponent />
    </Web3Providers>
  );
};
const TicketPageComponent = () => {
  let { address, eventId, token } = getQueryParams();

  // if (
  //   address === undefined &&
  //   eventId === undefined &&
  //   (token !== undefined || token !== null)
  // ) {
  //   const values = decryptStr(token as string);
  //   const parsed = new URLSearchParams(values);

  //   address = parsed.get("address");
  //   eventId = parsed.get("eventId");
  // }

  const [approvalCode, setApprovalCode] = React.useState("");
  const [awaitingForEntryConfirmation, setAwaitingForEntryConfirmation] =
    React.useState(false);
  const [isApproved, setIsApproved] = React.useState<EntryVerificationStatus>();
  const [anyErrors, setAnyErrors] = React.useState<string | undefined>(
    undefined
  );

  const [theAddress, setTheAddress] = React.useState(address);
  const [theEventId, setTheEventId] = React.useState(eventId);

  React.useEffect(() => {
    if (
      address === undefined &&
      eventId === undefined &&
      (token !== undefined || token !== null)
    ) {
      if (typeof token === "string" || token instanceof String) {
        const values = decryptStr(token as string);
        const parsed = new URLSearchParams(values);
        address = parsed.get("address");
        eventId = parsed.get("eventId");

        setTheAddress(address);
        setTheEventId(eventId);
      }
    }
  }, [address, eventId, token]);

  // console.log(token);
  // console.log(theAddress);
  // console.log(theEventId);
  return (
    <Web3Providers>
      <main className="timetable-page">
        <NavBar />

        <div>
          {" "}
          <PageHeader
            title="Wjazd na imprezę - tu odbieramy bilety :)"
            subtitle={`Bedzie haslo do wpisania dla osoby skanującej`}
          />
          <input
            type="password"
            className="ticket-view__input focus:ring-black-500"
            onChange={e => setApprovalCode(e.target.value)}
            value={approvalCode}
          />
          <YellowButton
            className="ticket-view__button btn-clicked theshadow"
            title={"Zatwierdź wejście"}
            disabled={awaitingForEntryConfirmation}
            action={async () => {
              setIsApproved(undefined);
              setAnyErrors(undefined);

              const encodedPassword = ethers.utils.sha256(
                ethers.utils.toUtf8Bytes(approvalCode)
              );

              setAwaitingForEntryConfirmation(true);
              const response = await fetch(
                `${process.env.API_BASE_URL}/entry`,
                {
                  method: "POST",
                  body: JSON.stringify({
                    address: theAddress,
                    eventId: theEventId,
                    approvalCode: encodedPassword,
                  }),
                  headers: {
                    "Content-type": `application/json`,
                  },
                }
              );

              const responseJson = (await response.json()) as {
                status: EntryVerificationStatus;
                reason?: string;
              };

              setIsApproved(responseJson.status);

              if (responseJson.reason) {
                setAnyErrors(responseJson.reason);
              }
              setAwaitingForEntryConfirmation(false);
            }}
          />
          {awaitingForEntryConfirmation && (
            <p className="ticket-view__text-result">
              Polaczek weryfikowany, poczekaj cierpliwie na odpowiedź :)
            </p>
          )}
          {isApproved === EntryVerificationStatus.APPROVED && (
            <p className="ticket-view__text-result">
              Polaczek zweryfikowany pomyślnie! Zaproś ziomeczka na melo
            </p>
          )}
          {isApproved === EntryVerificationStatus.DENIED && (
            <p className="ticket-view__text-result">
              Nie udało się zweryfikować polaka :(
              <br />
              Dane odczytane z QR:
              <ul>
                <li>Adres: {theAddress}</li>
                <li>Event Id: {theEventId}</li>
              </ul>
              {anyErrors ?? (
                <span>
                  Dodatkowe info o niepomyślnej weryfikacji: {anyErrors}
                </span>
              )}
            </p>
          )}
          {(!address || !eventId) && (
            <p className="text-center p-6">Coś jest nietak :(</p>
          )}
          <GoHomeButton />
        </div>
      </main>
    </Web3Providers>
  );
};

export default TicketPage;
export const Head: HeadFC = () => <SEO subtitle="Bilety" />;
